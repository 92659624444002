<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import c3api from "../../c3api";
import { useToast } from "vue-toastification";
import { LicenseManager } from "ag-grid-enterprise";
import { useRouter } from "vue-router";
import {
  saveGridState,
  loadGridState,
  resetGridFilterState,
  resetGridColState,
  connectToServerApi
} from "@/utils/agGridUtils";

import { useThemeStore } from "@/stores/themeStore";
import Warehouses from '@/components/common/Warehouses.vue';

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const loadCount = ref(0);
const router = useRouter();
const CARRIERS_EXTERNAL_FILTERS_KEY = "carriers-external-filter";
const GRID_SAVE_KEY = "carriers";
const CARRIERS_URL = "/carriers"

let gridApi;
const toast = useToast();
let context = ref(null);
const advancedFilterKeys = ["id", "name", "service_code", "service_sub_code", "portal_access", "carrier_type"];

const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: CARRIERS_URL,
    filterKey: CARRIERS_EXTERNAL_FILTERS_KEY,
    advancedFilterKeys: advancedFilterKeys
  };
  loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const onStateUpdated = function (params) {
  saveGridState(GRID_SAVE_KEY, gridApi);
};

const resetGridFiltersSettings = () => {
  resetGridFilterState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const resetGridColSettings = () => {
  resetGridColState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const warehouseFiltersChangedCallback = function () {
  gridApi.onFilterChanged();
};

const rowDoubleClicked = function (event) {
  if (event.data.id) {
    gridApi.showLoadingOverlay();
    window.location = `/carriers/${event.data.id}`;
  } else {
    console.log("Row clicked does not have an event.data.id!");
    console.log(event);
  }
};

const defaultColDef = {
  sortable: true,
  filter: "agTextColumnFilter",
  suppressHeaderMenuButton: true,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
  floatingFilter: true,
};

const columnDefs = [
  { field: "id", headerName: "System Id", colId: "id", cellDataType: "text" },
  { field: "name", headerName: "Name", colId: "name", cellDataType: "text" },
  { field: "service_code", headerName: "Service Code", colId: "service_code", cellDataType: "text" },
  { field: "service_sub_code", headerName: "Service Code 2", colId: "service_sub_code", cellDataType: "text" },
  { field: "portal_access", headerName: "Portal Access?", colId: "portal_access", cellDataType: "boolean" },
  { field: "carrier_type", headerName: "Type", colId: "carrier_type", cellDataType: "text" },
];

const gridOptions = {
  rowModelType: "serverSide",
  columnDefs: columnDefs,
  cacheBlockSize: 100,
  blockLoadDebounceMillis: 500,
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  getRowId: (params) => {
    return params.data.id;
  },
  onStateChanged: () => {
    saveGridState(GRID_SAVE_KEY, gridApi);
  },
};
</script>

<template>

  <div class="pa-4 w-100 h-100">
    <Warehouses :callBack="warehouseFiltersChangedCallback" :parent-filter="CARRIERS_EXTERNAL_FILTERS_KEY" />

    <div class="d-flex justify-end mx-4 w-full mt-4">
      <div class="align-center">
        <v-btn class="" variant="text" @click="resetGridFiltersSettings">Reset Filters</v-btn>
        <v-btn class="mr-2" variant="text" @click="resetGridColSettings">Reset Columns</v-btn>
      </div>
    </div>
    <AgGridVue style="width: 100%; height: 100%" :class="themeStore.agTheme" :columnDefs="columnDefs"
      :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady"
      @rowDoubleClicked="rowDoubleClicked" :context="context" @state-updated="onStateUpdated"></AgGridVue>
  </div>
</template>
